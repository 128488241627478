<template>
  <v-dialog v-model="active" fullscreen>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Review Summary for {{ selected.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="active = false">
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col>
            <v-list>
              <v-list-item>
                <v-list-item-content class="pa-0">
                  <v-list-item-subtitle>Chapel Date</v-list-item-subtitle>
                  <v-list-item-title>{{ stringFormatDate(selected.date) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="selected.speaker">
                <v-list-item-content class="pa-0">
                  <v-list-item-subtitle>Speaker</v-list-item-subtitle>
                  <v-list-item-title>{{ selected.speaker }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="selected.title">
                <v-list-item-content class="pa-0">
                  <v-list-item-subtitle>Title</v-list-item-subtitle>
                  <v-list-item-title>{{ selected.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Word Count</v-list-item-subtitle>
                  <v-list-item-title>{{ wordCount }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Previous Summaries</v-list-item-subtitle>
                  <v-list-item-title>{{ prevSummarySubmitted }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-card elevation="3" class="mb-5">
          <v-card-text v-html="selected.summary" ref="summaryEl"></v-card-text>
        </v-card>
        <v-btn-toggle v-model="status">
          <v-btn value="Approved" :outlined="status !== 'Approved'" color="success">
            <v-icon left>fas fa-check-circle</v-icon>
            Approve
          </v-btn>
          <v-btn value="Rejected" :outlined="status !== 'Rejected'" color="error">
            <v-icon left>fas fa-times-circle</v-icon>
            Reject
          </v-btn>
        </v-btn-toggle>
        <v-textarea v-model="reason" v-if="status==='Rejected'" :error="reason === ''" :error-messages="reason === '' ? ['Reason is required for rejected summaries'] : []" label="Reason for rejection" class="mt-3" outlined></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="active = false">Cancel</v-btn>
        <v-btn text color="primary" @click="save" :disabled="selected.status === status || (status === 'Rejected' && reason === '')">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
.theme--dark .border-right {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.theme--light .border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
<script>
import { ref, computed, watch } from '@vue/composition-api'
import { stringFormatDate } from '../../helpers/formatters'

export default {
  props: {
    selected: {
      type: Object,
      required: true
    }
  },
  setup (props, { root, emit }) {
    const studentService = root.$feathers.service('chapel/student')
    const summaryService = root.$feathers.service('chapel/summary')

    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const active = ref(false)
    const summaryEl = ref(null)
    const wordCount = ref(0)
    const status = ref('')
    const statusOptions = ref([ 'Pending', 'Approved', 'Rejected' ])
    const reason = ref('')
    const prevSummarySubmitted = ref(0)

    function activate () {
      // To-Do: Count the number of summaries submitted for the given term prior to this one. This will determine the minimum word length
      const query = { term: props.selected.term, pidm: props.selected.pidm, createdAt: { $lt: props.selected.createdAt }, $limit: 0 }
      summaryService.find({ query }).then(({ total }) => {
        prevSummarySubmitted.value = total
      })
      active.value = true
      if (typeof (props.selected.summary) === 'string' && props.selected.summary !== '') {
        root.$nextTick(() => {
          if (summaryEl.value != null) {
            wordCount.value = summaryEl.value.innerText.replace(/[\s]{2,}/gi, ' ').replace(/(^ )|( $)/gi, '').split(' ').length
          }
        })
      }
    }

    watch(() => props.selected, () => {
      status.value = props.selected.status || 'Pending'
    })

    async function save () {
      const $patch = {}
      let student = props.selected.student
      // Update the entry for the student
      if ('index' in props.selected) {
        // If props.selected.index is populated then that is the entry to update
        if (status.value === 'Rejected') {
          $patch['credits.' + props.selected.index + '.reason'] = reason.value
          $patch['credits.' + props.selected.index + '.credit'] = 0
        } else if (status.value === 'Approved') {
          $patch['credits.' + props.selected.index + '.reason'] = 'Summary Approved'
          $patch['credits.' + props.selected.index + '.credit'] = props.selected.creditValue || 1
        }
      } else {
        // Double-check that this chapel entry does not already exist for the student's record for the given term
        const { credits } = await studentService.get(student)
        if (credits != null) {
          let index = -1
          for (let i = 0; i < credits.length; i++) {
            if (credits[i].chapel === props.selected.chapel) {
              index = i
            }
          }
          if (index >= 0) {
            const $patch = { ['credits.' + index + '.credit']: props.selected.creditValue || 1 }
            if (status.value === 'Rejected') $patch['credits.' + index + '.reason'] = reason.value
          }
        }
      }
      // If not, then push a new entry into the credits array for the student with the chapel info
      // This shouldn't ever happen as the record should already be there, but we're putting the logic here just in case
      if (JSON.stringify($patch) === '{}') {
        $patch.$push = { credits: {
          credit: status.value === 'Rejected' ? 0 : props.selected.creditValue || 1,
          chapel: props.selected.chapel,
          date: props.selected.createdAt,
          type: 'Summary',
          reason: status.value === 'Rejected' ? reason.value : null,
          summary: props.selected._id
        } }
      }
      await studentService.patch(student, $patch)
      // Update the summary record to reflect the new status
      await summaryService.patch(props.selected._id, { status: status.value, reviewedBy: user.value.username })
      emit('updated', { status: status.value })
      active.value = false
    }

    return {
      active,
      summaryEl,
      wordCount,
      status,
      statusOptions,
      reason,
      prevSummarySubmitted,
      activate,
      save,
      stringFormatDate
    }
  }
}
</script>
